import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/managePlatform",
    name: "ManagePlatform",
    component: () => import("../views/managePlatform.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/design",
    name: "Design",
    component: () => import("../views/Design.vue"),
  },
  {
    path: "/statistic",
    name: "Statistic",

    component: () => import("../views/Statistic.vue"),
  },
  {
    path: "/data",
    name: "Data",
    component: () => import("../views/Data.vue"),
  },
  {
    path: "/artificial",
    name: "Artificial",

    component: () => import("../views/Artificial.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/firstNews",
    name: "FirstNews",

    component: () => import("../views/articles/firstNews.vue"),
  },
  {
    path: "/secondNews",
    name: "SecondNews",

    component: () => import("../views/articles/secondNews.vue"),
  },
  {
    path: "/thirdNews",
    name: "ThirdNews",

    component: () => import("../views/articles/thirdNews.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
