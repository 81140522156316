<template>
  <div>
    <footer class="footer text-light" style="background-color: #575757;">
  <div class="bottom-bar-height"></div>
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-xs-12"> 
        <img class="logo" src="../assets/logo3.png" style="margin-bottom: 30px;">
        <div>
          <p class="text-style-title">地址：</p>
          <p class="text-style">西安市碑林区南二环东段交大出版传媒大厦</p>
        </div>
        <div>
          <p class="text-style-title">电话：</p>
          <p class="text-style">029-82569656</p>
        </div>
      </div>
      <div class="col-sm-4 col-xs-6  col-sm-4-middle"> 
        <div class="text-style-title" style="margin-bottom: 50px;">快速导航</div>
        <div style="margin-bottom: 10px;"><a class="text-style" href="/design">研究方案设计指导</a></div>
        <div style="margin-bottom: 10px;"><a class="text-style" href="/managePlatform">临床医学研究管理平台</a></div>
        <div style="margin-bottom: 10px;"><a class="text-style" href="/statistic">医学统计分析</a></div>
        <div style="margin-bottom: 10px;"><a class="text-style" href="/data">临床数据管理</a></div>
        <div style="margin-bottom: 10px;"><a class="text-style" href="/artificial">临床人工智能</a></div>
      </div>
      <div class="col-sm-4 col-xs-6"> 
        <div class="text-style-title" style="margin-bottom: 50px;">关注我们</div>
        <div style="display: flex; justify-content: space-between;">
          <div>
            <img src="../assets/gong.png" alt="" style="width: 90px;height:90px;display: block;">
            <p class="text-style">关注公众号</p>
          </div>
          <div style="margin: 0px 10px;">
            <img src="../assets/xiao.png" alt="" style="width: 90px;height:90px;display: block;">
            <p class="text-style">进入小程序</p>
          </div>
        </div>
        <!-- <div  class="bottom">©2023西安鸿创医学科技有限公司All Rights Reserved浙ICP备</div> -->
      </div>
      <div class="bottom">©2024西安鸿创医学科技有限公司,陕ICP备2022004868号-2</div>
    </div>
  </div>
</footer>
  </div>
</template>
  
<script>
export default {
  name: 'AppFooter',
};
</script>
  
<style scoped>
/* Common styles for both mobile and PC */
.text-style-title {
  font-size: 16px;
  color: #fff;
}

.text-style {
  font-size: 16px;
}



/* 移动端样式 */
@media screen and (max-width: 767px) {
  .footer {
    /* position: fixed; */
    /* bottom: 0;
    left: 0; */
    width: 100%;
    /* z-index: 1000; */
    /* 可选：根据实际需求设置层级 */
    height: auto;
  }
  .bottom-bar-height {
  height: 20px;
}
  .col-sm-4-middle{
    display: none;
  }
  .logo{
    display: none;
  }
  .col-sm-4{
    width: 43%; 
  }
  .bottom{
    width: 100%;
    font-size: 12px;
    border-top: 1px solid gray;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: auto;
  }
}

/* PC端样式 */
@media screen and (min-width: 768px) {
  .bottom-bar-height {
  height: 80px;
}
  a {
    text-decoration: none;
    color: #fff;
  }

  .text-style-title {
    font-size: 16px;
    color: #fff;
  }

  .text-style {
    font-size: 16px;

  }

  .bottom-bar-height {
    height: 80px;
  }
  .bottom{
    font-size: 16px;
    /* border-top: 1px solid gray; */
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: auto;
  }
}
</style>
  