<template>
  <div class="app">
    <div class="header">
      <!-- 顶部全局组件 -->
      <Navbar />
    </div>
      <div class="content">
        <!-- 展示路由组件的区域 -->
        <router-view />
      </div>
    <!-- <div class="footer"> -->
      <BottomBar />
    <!-- </div> -->
  </div>
</template>
<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BottomBar from '@/components/BottomBar.vue'

export default {
  components: {
    Navbar,
    BottomBar
  }
}
</script>
<style >
/* 通用样式，可根据需要进行调整 */
body {
  margin: 0;
  padding: 0;
  list-style-type: none;  
}

/* #app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
} */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.footer{
  width: 100%;
  height:auto;
}
.content {
  margin-top: 80px;
  overflow-y: auto;
  height: auto; 
  /* 这个是中间内容的高度， */
}
.content::-webkit-scrollbar {
  display: none;
}
</style>
