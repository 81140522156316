<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light " style="background-color: #48545d;">
      <a class="navbar-brand" href="/">
        <img src="../assets/logo.png" alt="" href="/">
      </a>
      <!-- btn btn-primary 在button添加 是按钮变为蓝色 -->
      <button class="navbar-toggler btn btn-primary" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse clearfix " id="navbarNavDropdown" >
        <ul class="navbar-nav " style="display: flex;flex: 1;  justify-content:space-around;">
          <!--        <li class="nav-item active">-->
          <!--          <a class="nav-link active" href="/">首页</a>-->
          <!--        </li>-->
          <li class="nav-item">
            <a class="nav-link" href="/design" style="">研究方案设计指导</a>
          </li>
          <li class="nav-item">
            <a href="managePlatform" class="nav-link">临床医学研究管理平台</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/statistic">医学统计分析</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/data">临床数据管理</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/artificial">临床人工智能</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/news">新闻资讯</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/contact">联系我们</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>


<script>

export default {
  name: 'Navbar',
  props: {

  },
  data() {
    return {

    }
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/* 去掉浏览器默认的样式 */
// .btn:active:focus,
// .btn:focus {
//   outline: none;
// }

// 导航栏在移动端的时候 右边点击出现下黑框
// .navbar-toggler:focus,
// .navbar-toggler:active,
// .navbar-toggler-icon:focus {
//   outline: none;
//   border: none;
//   box-shadow: none;
// }
@media screen and (max-width: 767px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* 可选：根据实际需求设置层级 */
  }
}
.nav-link {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
</style>
